// @ts-check
import React from "react"

const ThickArrow = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg fill="currentColor" viewBox="0 0 25 24" {...props}>
    <path d="M13.3775 23.4995l10.277-10.2827.0191-.0015c.667-.6673.667-1.7456 0-2.4129L13.3775.500473c-.6669-.667298-1.7447-.667298-2.4116 0-.6669.667297-.6669 1.745627 0 2.412917l7.3716 7.37581H1.883c-.940791 0-1.709882.7702-1.709882 1.7108 0 .9413.7698 1.7108 1.709882 1.7108h16.4553l-7.3717 7.3758c-.6669.6673-.6669 1.7456 0 2.4129.6669.668 1.744.668 2.4109 0z" />
  </svg>
)

export default ThickArrow
